import React from "react";
import ReactDOM from "react-dom";
import { RotatingLines } from "react-loader-spinner";

// Crear un contenedor en el DOM para el portal
const loaderRoot = document.getElementById("loader-root") || (() => {
  const div = document.createElement("div");
  div.id = "loader-root";
  document.body.appendChild(div);
  return div;
})();

function Loader() {
  return ReactDOM.createPortal(
    <div style={styles.overlay}>
      <RotatingLines
        strokeColor="darkgrey"
        strokeWidth="5"
        animationDuration="0.75"
        width="96"
        visible={true}
      />
    </div>,
    loaderRoot
  );
}

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0)", // Fondo semitransparente
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999, // Asegurarse de que esté por encima de todo
  },
};

export default Loader;

// import { RotatingLines } from "react-loader-spinner";

// function Loader() {
//   return (
//     <RotatingLines
//       strokeColor="grey"
//       strokeWidth="5"
//       animationDuration="0.75"
//       width="96"
//       visible={true}
//     />
//   )
// }
// export default Loader