import React, { useRef, useState } from "react";

import FormAddProfessional from "./FormAddProfessional";
import { validate } from "./Validate";
//Alert notifications
import {
  NotificationContainer, 
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { uploadFile } from "../../../../../Firebase/Firebase";
import { getProfesionales, getTurnos, postProfesionales } from "../../../../../Redux/Action/Actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Loader from "../../../../GifsDeEspera/LoaderSpinner";
 
 
function AddProfessional(props) {
  
  const [errors, setErrors] = useState({});
  const [imageId, setImageId] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(""); // Previsualización de la imagen
  const fileInputRef = useRef(null); // Referencia al input de archivo
  const dispatch=useDispatch()
  // useEffect(()=>{
  //   dispatch(getProfesionales(props.token))
  //   // dispatch(getTurnos())
  // },[dispatch])
  const [post, setPost] = useState({
    idProfesional: "",
    nombre: "",
    apellido: "",
    celular: "",
    email: "",
    password: "",
    matricula: "",
    especialidad: "",
    imagenProfesional: "",
  });
  const token= JSON.parse(window.localStorage.getItem('token'))
  
  async function Actualizar(token){
    
   dispatch(getProfesionales(token));
   dispatch(getTurnos(token));
}

  // //======= HANDLE SELECT ==================
  const handleSelelect = (seletedOptions) => {
    console.log('seletedOptions', seletedOptions)
    
    // const selection = seletedOptions.map((e) => e.value);
    // console.log('selection', selection)
    setPost({ ...post, especialidad: seletedOptions });
    
    };

  // // ===================HANDLE IMAGE===============================================

  const handleImageId = async (file) => {
    //e.preventDefault();
    if (!file) return;

    // Genera la previsualización
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
    try {
      let url = await uploadFile(file);
      setImageId(url);
    } catch (err) {
      console.log(err);
    }
  };
  // Reinicia la previsualización
  const resetImage = () => {
    setPost({ ...post, imagenProfesional: '' })
    setPreviewUrl("");
    setImageId(""); // También reinicia la URL de Firebase si es necesario
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Resetea el input manualmente
    }
  };

 // Disparar clic en el input desde un botón personalizado
 const triggerFileInput = () => {
  if (fileInputRef.current) {
    fileInputRef.current.click();
  }
};

  // //======================= HANDLE CHANGE=========================================
  const handleChange = (e) => {
    e.preventDefault();
    setPost({
      ...post,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validate({
        ...post,
      })
    );
  };
  // //===============================================================
  let especialidad=[]
  post.especialidad.length>0 &&   especialidad.push(post.especialidad.map((e) => e.value).toString())
  let newProfesional = {
    idProfesional: post.idProfesional, 
    nombre: post.nombre
      ?.split(" ")
      .map((el) => el.charAt(0).toUpperCase() + el.toLowerCase().slice(1))
      .join(" "),
    apellido: post.apellido
      ?.split(" ")
      .map((el) => el.charAt(0).toUpperCase() + el.toLowerCase().slice(1))
      .join(" "),
    celular: post.celular,
    email: post.email,
    password: post.password,
    matricula: post.matricula,
    especialidad: especialidad[0],
    imagenProfesional: imageId,
  };
  // console.log('newProfesional', newProfesional.especialidad)
  
 
//==========================================================================
 
  let body = [
      `DNI:${newProfesional.idProfesional}`,`Nombre:${newProfesional.nombre}`,`Apellido:${newProfesional.apellido}`,`Celular: ${ newProfesional.celular}`,`Email: ${newProfesional.email}`,`Password:${newProfesional.password}`,`Matricula:${newProfesional.matricula}`,`Especialidad: "${newProfesional.especialidad}`
    ];
  

  // //=========================== HANDLE SUBMIT====================================
  
  //AGREGAR TOKEN PARA MANDAR A LA ACTION

  async function handleSubmit (e) {
    e.preventDefault();
    setIsDataLoading(true);

    let error = await validate(post);

    if (Object.keys(error).length === 0) {
      try {
        let doc= await postProfesionales(newProfesional,props.token)
        setIsDataLoading(false);
        console.log("post", newProfesional);
        // setShow(true);
        Actualizar(props.token)
        NotificationManager.success("Bien Hecho!", "Profesional Añadido", 3000);
        getProfesionales(token)
     
          // Reinicia la previsualización de la imagen
        setImageId("");
        setPreviewUrl("");
        setPost({
            idProfesional: "",
            nombre: "",
            apellido: "",
            celular: "",
            email: "",
            password: "",
            matricula: "",
            especialidad: [],
            imagenProfesional: "",
         });

        //  console.log("post", post);
         
      } catch (e) {
        console.log("error de firebase", e);
      }
    } else {
      setIsDataLoading(false);
      let errorA = Object.values(error).join(', ');

      NotificationManager.error(`${errorA}`, "ATENCION!", 10000);
      // setShow(true);
      // setErr(errorA);
    }
  };
  // console.log("post", post);

  return (
    <>
    {isDataLoading && <Loader/>}

      <FormAddProfessional
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleSelelect={handleSelelect}
        handleImageId={handleImageId}
        post={post}
        errors={errors}
        profesional={body}
        previewUrl={previewUrl} // URL de previsualización
        resetImage={resetImage} // Nueva función para reiniciar la imagen
        triggerFileInput={triggerFileInput}
        fileInputRef={fileInputRef}
       
       
      />
      <NotificationContainer />
    </>
  );
}

export default AddProfessional;
