export const GET_TURNOS= 'get_turnos'
export const GET_USUARIOS= 'get_usuarios'
export const GET_PROFESIONALES='get_profesionales'
export const GET_ADMIN= 'get_admin'
export const POST_TURNOS= 'post_turnos'
export const POST_USUARIOS= 'post_usuarios'
export const POST_HISTORIA= 'post_historia'
export const GET_HISTORIAS= 'get_historia'
export const MESSAGE= 'message'
export const POST_ADMIN= 'post_admin'
export const HORAS_CREADAS='horas_creadas'
export const GET_PROFESIONAL_ID = 'GET_PROFESIONAL_ID'
export const RESERVA_TURNO_ADMIN = 'reserva_turno_admin'
export const REGISTER = 'REGISTER'
export const SESIONACTIVA = 'SESIONACTIVA'

 