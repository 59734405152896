import React from "react";



function FormImage({
  handleImageId,
  previewUrl,
  resetImage,
  triggerFileInput,
  fileInputRef
}) {


  return (
    <>

      <input
        type="file"
        ref={fileInputRef}
        onChange={(e) => handleImageId(e.target.files[0])}
        style={{ display: "none" }} // Ocultar el input
        accept="image/*" // Aceptar solo imágenes
      />
       {/* Botón personalizado */}
       <button
        type="button"
        onClick={triggerFileInput}
        style={{
          display: "inline-block",
          margin: "10px 0",
          backgroundColor: "#007bff",
          color: "#fff",
          border: "none",
          padding: "10px 15px",
          cursor: "pointer",
          borderRadius: "5px",
        }}
      >
        Selecciona una imagen
      </button>

      {/* Previsualización y botón para eliminar */}
      {previewUrl && (
        <div className="mb-3">
          <img
            src={previewUrl}
            alt="Previsualización"
            style={{
              maxWidth: "200px",
              maxHeight: "200px",
              border: "1px solid #ddd",
              padding: "5px",
              display: "block",
              margin: "10px 0",
            }}
          />
          <button
            type="button"
            onClick={resetImage}
            style={{
              display: "inline-block",
              backgroundColor: "#f44336",
              color: "#fff",
              border: "none",
              padding: "10px 15px",
              cursor: "pointer",
              borderRadius: "5px",
            }}
          >
            Eliminar imagen
          </button>
        </div>
      )}

      {/* <div> */}
        {/* <label className="block text-xl font-medium text-custom-green my-2">
          Imagen Principal
        </label>
        <div className=" flex ">
          {Object.keys(imageFile).length > 0 || imageFile.length > 0 ? (
            <div className="relative">
              <img
                src={
                  typeof imageFile === "object" && imageFile.main
                    ? imageFile.url
                    : (Array.isArray(imageFile) &&
                        imageFile[0] &&
                        imageFile[0].secure_url) ||
                      null
                }
                alt="Project Image"
                className="object-cover h-36 w-full md:h-52"
                
              />
              <button
                type="button"
                onClick={handleRemoveMainImage}
                className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1"
              >
                <FaTrash />
              </button>
            </div>
          ) : (
            <div className="mt-2 flex items-center">
              <label className="cursor-pointer">
                <FaPlus
                  className="text-white bg-custom-green p-2 transition-all duration-700 rounded outline hover:text-custom-green hover:bg-white hover:outline-2 hover:outline-custom-green "
                  size={30}
                />
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  placeholder="Portada"
                  onChange={handleImageChange}
                  className="hidden"
                />
              </label>
            </div>
          )}
        </div>      
      </div> */}
    </>
  );
}

export default FormImage;