// import { style } from '@mui/system';
import React from 'react';
import Select from 'react-select';
import makeAnimates from 'react-select/animated'
// import { post } from '../../../../../../../back/src/routes';
const animatedComponents= makeAnimates()




function SelectEspecialidades({handleSelelect, options, value}) {
// console.log('value', value)

    return (
      <> 
    <Select 
      closeMenuOnSelect={false}
      components={animatedComponents}
      value={value}
      isMulti 
      options={options}
      onChange={handleSelelect}
      name={'especialidades'}
      placeholder="Selecciona tu especialidad"
      
    />
      </>
    )
}

export default SelectEspecialidades