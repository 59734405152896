import React from "react";
import styles from "./Image2.module.css";

function Image() {
  return (
    <div className={styles.ImageTopFooter}>
      <p>.</p>
    </div>
  );
}

export default Image;
