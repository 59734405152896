import React from 'react'

function Redes() {
  return (
    <div>
                 <a
            href="https://www.facebook.com/centrodeterapiaspequenosgigantes "
            target="_blank"
          >
            faceboock
            </a>
    </div>
  )
}

export default Redes